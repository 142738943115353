import React, { useState } from "react";
import { Modal, message, Form, Input } from "antd";
import axios from "axios";
import { useApi } from "../../context/useApi";
import { IoMdCloseCircle } from "react-icons/io";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utils/commonFunctions";
const ROOT_URL = process.env.REACT_APP_ROOT_URL;
const ResetPassword = ({
  record,
  apiName,
  recordName,
  oldPasswordField = false,
  resetButtonName = "Reset Password",
  okText = "Save",
  modalTitle = "Reset Password",
}) => {
  const [form] = Form.useForm();
  const { config, setConfig } = useApi();
  const [resetConfirmSpin, setResetConfirmSpin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async (values) => {
    delete values.confirmPassword;

    setResetConfirmSpin(true);
    if (record && record._id) {
      values._id = record._id;
    }

    return await new Promise((resolve, reject) => {
      let Authorization = null;
      let user = JSON.parse(localStorage.getItem("userInfo"));
      if (user && user.token) {
        Authorization = user.token;
      }
      let headers = {
        "Content-Type": "application/json",
        Authorization: Authorization,
        APIType: "web",
      };
      axios({
        url: `${ROOT_URL}/${apiName}`,
        method: "put",
        headers: headers,
        data: values,
      })
        .then(function (response) {
          if (response && response.data && response.data.Record) {
            if (response.data.status === "SUCCESS") {
              if (config && config[recordName] && !oldPasswordField) {
                let tableData = config[recordName].tableData;
                let newArray = [];
                let updateObj = {};
                tableData.map((Item) => {
                  if (Item._id === response.data.Record.tableData[0]._id) {
                    newArray.push(response.data.Record.tableData[0]);
                  } else {
                    newArray.push(Item);
                  }
                  return Item;
                });
                updateObj = {
                  ...response.data.Record,
                  tableData: newArray,
                };
                setConfig(recordName, updateObj);
                setIsModalOpen(false);
                setResetConfirmSpin(false);
                showSuccessNotification(response);
              } else {
                if (oldPasswordField) {
                  showSuccessNotification(response);
                  setIsModalOpen(false);
                }
                setResetConfirmSpin(false);
                setConfig(recordName, response.data.Record);
              }
            }
          } else if (response.data.status === "ERROR") {
            setResetConfirmSpin(false);
            message.error(response.data.message);
          } else if (response.data.status === "SUCCESS") {
            setResetConfirmSpin(false);
            message.success(response.data.message);
            setIsModalOpen(false);
            form.resetFields();
          }
          resolve();
        })
        .catch(function (error) {
          setResetConfirmSpin(false);
          showErrorNotification(error);
          reject();
        });
    }).catch(() => {
      setResetConfirmSpin(false);
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  return (
    <>
      <span
        className="pointer color-515151 font-16 regular-font"
        onClick={() => showModal()}
      >
        {resetButtonName}
      </span>
      <Modal
        className="form-modal"
        title={modalTitle}
        open={isModalOpen}
        okText={okText}
        cancelText="Cancel"
        confirmLoading={resetConfirmSpin}
        closeIcon={<IoMdCloseCircle size="20" />}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              handleOk(values);
            })
            .catch((info) => {});
        }}
        onCancel={handleCancel}
      >
        <hr />
        <div className="px-3">
          {oldPasswordField && (
            <h4 className="font-16 mb-3 regular-font">
              Enter a new password for your account.
            </h4>
          )}
          <Form
            layout="vertical"
            initialValues={record}
            name="Form"
            form={form}
          >
            {oldPasswordField && (
              <Form.Item
                name="oldPassword"
                label="Old Password"
                rules={[
                  { required: true, message: "Old Password is required" },
                ]}
              >
                <Input.Password placeholder="Type current password" />
              </Form.Item>
            )}
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Password is required" }]}
            >
              <Input.Password placeholder="Type new password" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              rules={[
                {
                  required: true,
                  message: "Please Enter Confirm Password",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Password is not match"));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Re-Type password" />
            </Form.Item>
          </Form>
        </div>
        <hr />
      </Modal>
    </>
  );
};
export default ResetPassword;
