import React, { useEffect } from "react";
import SmallCardGraph from "../../components/Graphs/SmallCardGraph";
import { Col, Row } from "antd";
import Notification from "../../components/Notification";
import FillAreaChart from "../../components/Graphs/FillAreaChart";
import LineAreaChart from "../../components/Graphs/LineAreaChart";
import { useApi } from "../../context/useApi";
const Index = () => {
  const { DataGetAction, config } = useApi();
  const getAllDashboardData = () => {
    DataGetAction("adminDashboardCards", { query: "all" }, "dashboardCard");
    DataGetAction("adminMonthlyAnalytics", { query: "all" }, "monthlyAnalytic");
    DataGetAction("adminDailyAnalysis", { query: "all" }, "dailyAnalysis");
    DataGetAction("getNotificationForAdmin", { query: "all" }, "notification");
  };

  useEffect(getAllDashboardData, []);

  return (
    <>
      <Row>
        <Col xl={16} lg={16} md={24} sm={24} xs={24}>
          <Row className="gap-md-0 gap-3">
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="pe-md-3">
              <SmallCardGraph
                count={
                  (config &&
                    config.dashboardCard &&
                    config.dashboardCard.totalUser) ||
                  0
                }
                desc="Total Stores"
                stroke="#C39FDF"
                fill="#F3ECF9"
              />
            </Col>
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="pe-md-3">
              <SmallCardGraph
                count={
                  (config &&
                    config.dashboardCard &&
                    config.dashboardCard.activeUser) ||
                  0
                }
                desc="Total Active Stores"
                stroke="#B8E886"
                fill="#E4F9D7"
              />
            </Col>
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="pe-lg-3">
              <SmallCardGraph
                count={
                  (config &&
                    config.dashboardCard &&
                    config.dashboardCard.blockUser) ||
                  0
                }
                desc="Total Blocked Stores"
                stroke="#F198CA"
                fill="#FCEAF4"
              />
            </Col>
          </Row>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="pe-lg-3 mb-lg-0 mb-4">
              <FillAreaChart data={config && config.monthlyAnalytic} />
            </Col>
          </Row>
        </Col>
        <Col xl={8} lg={8} md={24} sm={24} xs={24} className="h-100">
          <Notification data={config && config.notification} />
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <LineAreaChart data={config && config.dailyAnalysis} />
        </Col>
      </Row>
    </>
  );
};

export default Index;
