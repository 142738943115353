import React from "react";
import Table from "../../components/Table";
import Widget from "../../components/widget";
import Action from "../../components/Action";
import EditUser from "./components/EditUser";
import TableHeader from "./components/TableHeader";
import DeleteModal from "../../components/Modal/DeleteModal";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import ResetPassword from "../../components/Modal/ResetPassword";
import { renderStatus } from "../../utils/commonFunctions";
import UserCards from "./components/UserCards";
const Index = () => {
  const renderActions = (record) => {
    return (
      <div className="d-flex flex-column">
        {record.status === "block" && (
          <ConfirmationModal
            buttonName="Active"
            title="Are you sure you want to activate this user?"
            selectedItem={{ _id: record._id, status: "active" }}
            apiName="updateUserStatus"
            recordName="userList"
            oKText="Ok"
            cancelText="Cancel"
          />
        )}
        {record.status === "active" && (
          <ConfirmationModal
            buttonName="Block"
            title="Are you sure you want to block this user?"
            selectedItem={{ _id: record._id, status: "block" }}
            apiName="updateUserStatus"
            recordName="userList"
            oKText="Ok"
            cancelText="Cancel"
          />
        )}
        {record.status === "active" && <EditUser record={record} />}
        <DeleteModal
          buttonName="Delete"
          title="Are you sure you want to delete this user?"
          selectedItem={{ _id: record._id }}
          apiName="deleteUser"
          recordName="userList"
          oKText="Delete"
          cancelText="Cancel"
        />
        {record.status === "active" && (
          <ResetPassword
            apiName="resetPassword"
            record={record}
            recordName="userList"
          />
        )}
      </div>
    );
  };
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.userId}</span>,
      key: "userId",
    },
    {
      title: "Full Name",
      render: (record) => (
        <span className="text-capitalize">{record.fullName || "---"}</span>
      ),
      key: "fullName",
    },
    {
      title: "Email",
      render: (record) => <span>{record.email || "---"}</span>,
      key: "email",
    },
    {
      title: "Phone",
      render: (record) => <span>{record.phoneNumber || "---"}</span>,
      key: "phoneNumber",
    },
    {
      title: "Package Name",
      render: (record) => (
        <span className="text-capitalize">{record.packageName || "Free"}</span>
      ),
      key: "packageName",
    },
    {
      title: "Store Name",
      render: (record) => (
        <span className="text-capitalize">{record.storeName || "---"}</span>
      ),
      key: "storeName",
    },
    {
      title: "Store Address",
      render: (record) => <span>{record.storeAddress || "---"}</span>,
      key: "storeAddress",
    },
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];
  return (
    <div>
      <UserCards />
      <Widget Header={<TableHeader />}>
        <Table columns={columns} recordName="userList" apiName="getUsersList" />
      </Widget>
    </div>
  );
};

export default Index;
