import React from "react";
import EditFormModal from "../../../components/Modal/EditFormModal";
import PackageForm from "./PackageForm";
import { Form } from "antd";

const EditPackage = ({ record }) => {
  const [form] = Form.useForm();

  return (
    <EditFormModal
      apiName="updatePackage"
      modalTitle="Edit Package"
      recordName="packageList"
      initialValues={record}
      Form={Form}
      form={form}
    >
      <PackageForm Form={Form} form={form} />
    </EditFormModal>
  );
};

export default EditPackage;
