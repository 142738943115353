import React from "react";
// import AddPackage from "./AddPackage";
import Title from "../../../components/Title";
const TableHeader = () => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <Title text="Packages" />
      {/* <AddPackage /> */}
    </div>
  );
};

export default TableHeader;
