import React from "react";
import Table from "../../components/Table";
import Widget from "../../components/widget";
import TableHeader from "./components/TableHeader";
import Action from "../../components/Action";
import EditCategory from "./components/EditCategory";
import DeleteModal from "../../components/Modal/DeleteModal";
import moment from "moment/moment";
const Index = () => {
  const renderActions = (record) => {
    return (
      <div className="d-flex flex-column ">
        <EditCategory record={record} />
        <DeleteModal
          buttonName="Delete"
          title="Are you sure you want to delete this category?"
          selectedItem={{_id:record._id}}
          apiName="deleteCategory"
          recordName="categoryList"
          oKText="Delete"
          cancelText="Cancel"
        />
      </div>
    );
  };
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.categoryId}</span>,
      key: "categoryId",
      width: "100px",
    },
    {
      title: "Name",
      render: (record) => <span className='text-capitalize'>{record.categoryName}</span>,
      key: "name",
      width: "200px",
    },
    {
      title: "Created At",
      render: (record) => <span>{moment(record.createdAt).format('DD-MM-YYYY')}</span>,
      key: "createdAt",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];
  return (
    <div>
      <Widget Header={<TableHeader />}>
        <Table
          columns={columns}
          apiName="getCategory"
          recordName="categoryList"
        />
      </Widget>
    </div>
  );
};

export default Index;
