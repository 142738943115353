import React from "react";
import SideBarLogoImage from "../../../assets/images/sidebarLogoImage.png";
const SidebarLogo = ({ collapsed }) => {
  return (
    <div
      style={{ height: "63.5px" }}
      className="text-white d-flex align-items-center justify-content-center"
    >
      <div className="d-flex gap-2 align-items-center">
        <img src={SideBarLogoImage} alt="--" width='30px' />
        {!collapsed && (
          <p className="primary-color font-20 regular-font mb-0">Wabyo</p>
        )}
      </div>
    </div>
  );
};

export default SidebarLogo;
