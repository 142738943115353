import React from "react";
import Table from "../../components/Table";
import Widget from "../../components/widget";
import Action from "../../components/Action";
import EditPackage from "./components/EditPackage";
import ViewDescription from "./components/ViewDescription";
import TableHeader from "./components/TableHeader";
const Index = () => {
  const renderActions = (record) => {
    return (
      <div className="d-flex flex-column">
        <span></span>
        <EditPackage record={record} />
      </div>
    );
  };
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.packageId}</span>,
      key: "packageId",
      width: "100px",
    },
    {
      title: "Package Name",
      render: (record) => (
        <span className="text-capitalize">{record.packageName}</span>
      ),
      key: "packageName",
      width: "200px",
    },
    {
      title: "Price",
      render: (record) => <span>{record.packagePrice}</span>,
      key: "packagePrice",
      width: "200px",
    },
    {
      title: "Description",
      render: (record) => (
        <ViewDescription initialValues={record}>
          {record.description && record.description.length > 30
            ? record.description.slice(0, 30) + "..."
            : record.description}
        </ViewDescription>
      ),
      key: "description",
      width: "300px",
    },
    {
      title: "Users",
      render: (record) => <span>{record.users || 0}</span>,
      key: "users",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];
  return (
    <div>
      <Widget Header={<TableHeader />}>
        <Table
          columns={columns}
          apiName="getPackageList"
          recordName="packageList"
        />
      </Widget>
    </div>
  );
};

export default Index;
