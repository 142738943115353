import React from "react";
import logo from "../../assets/images/logo.png";
import { Col, Row } from "antd";
const Index = ({ children, title, content }) => {
  return (
    <div className="auth-background">
      <div className="d-flex align-items-center justify-content-center auth-bubble-background">
        <div style={{ width: "850px" }}>
          <Row className="m-0 p-0">
            <Col
              xl={10}
              lg={10}
              md={10}
              sm={24}
              xs={24}
              className="auth-left-side"
            >
              <div className="d-flex justify-content-center p-3">
                <img src={logo} width="150px" height="150px" alt="--" />
              </div>
              <div className="d-flex justify-content-center pt-5">
                <p className="text-white regular-font font-24 text-center py-4 px-3">
                  {content}
                </p>
              </div>
            </Col>
            <Col
              xl={14}
              lg={14}
              md={14}
              sm={24}
              xs={24}
              className="p-5 auth-card"
            >
              <div className="w-100">
                <h4 className='bold-font font-24 mb-4'>{title}</h4>
                <div>{children}</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Index;
