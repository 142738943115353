import React from "react";
import Title from "../../../components/Title";
import Filter from "../../../components/Filter";

const TableHeader = () => {
  return (
    <div className="d-flex align-items-center justify-content-between flex-wrap">
      <Title text="Users" />
      <Filter
        SearchBox={true}
        SelectBox={true}
        SearchBoxWidth="200px"
        SelectBoxWidth="200px"
        searchPlaceholder="ID / Name / Email"
        selectPlaceholder="Status"
        apiName="getUsersList"
        recordName="userList"
        label="label"
        value="value"
        Options={[
          { label: "All", value: "all" },
          { label: "Active", value: "active" },
          { label: "Block", value: "block" },
        ]}
      />
    </div>
  );
};

export default TableHeader;
