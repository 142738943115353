import React from "react";
import EditFormModal from "../../../components/Modal/EditFormModal";
import CategoryForm from "./CategoryForm";
import { Form } from "antd";

const EditCategory = ({ record }) => {
  const [form] = Form.useForm();
  return (
    <EditFormModal
      apiName="updateCategory"
      modalTitle="Edit Category"
      recordName="categoryList"
      initialValues={record}
      Form={Form}
      form={form}
    >
      <CategoryForm Form={Form} />
    </EditFormModal>
  );
};

export default EditCategory;
