import React from "react";
import AddFormModal from "../../../components/Modal/AddFormModal";
import { Form } from "antd";
import CategoryForm from "./CategoryForm";

const AddCategory = () => {
  const [form] = Form.useForm();
  return (
    <AddFormModal
      apiName="addCategory"
      recordName="categoryList"
      modalTitle="Add Category"
      Form={Form}
      form={form}
    >
      <CategoryForm Form={Form} />
    </AddFormModal>
  );
};

export default AddCategory;
