import React, { useState } from "react";
import { Modal } from "antd";
import axios from "axios";
import { useApi } from "../../context/useApi";
import { IoMdCloseCircle } from "react-icons/io";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utils/commonFunctions";
const ROOT_URL = process.env.REACT_APP_ROOT_URL;
const EditFormModal = ({
  Form,
  form,
  children,
  initialValues,
  modalTitle = "Edit",
  editButtonName = "Edit",
  method = "put",
  apiName,
  recordName,
  okText = "Update",
  cancelText = "Cancel",
  width,
}) => {
  const { config, setConfig } = useApi();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editConfirmSpin, setEditConfirmSpin] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async (values) => {
    if (values && values.phoneNumber && !values.phoneNumber.includes("+")) {
      values['phoneNumber'] = "+" + values.phoneNumber;
    }

    setEditConfirmSpin(true);
    if (initialValues && initialValues._id) {
      values._id = initialValues._id;
    }
    return await new Promise((resolve, reject) => {
      let Authorization = null;
      let user = JSON.parse(localStorage.getItem("userInfo"));
      if (user && user.token) {
        Authorization = user.token;
      }
      let headers = {
        "Content-Type": "application/json",
        Authorization: Authorization,
        APIType: "web",
      };
      axios({
        url: `${ROOT_URL}/${apiName}`,
        method: method,
        headers: headers,
        data: values,
      })
        .then(function (response) {
          if (response && response.data && response.data.Record) {
            if (response.data.status === "SUCCESS") {
              if (config && config[recordName]) {
                let tableData = config[recordName].tableData;
                let newArray = [];
                let updateObj = {};
                tableData.map((Item) => {
                  if (Item._id === response.data.Record.tableData[0]._id) {
                    newArray.push(response.data.Record.tableData[0]);
                  } else {
                    newArray.push(Item);
                  }
                  return Item;
                });
                updateObj = {
                  ...response.data.Record,
                  tableData: newArray,
                };
                setConfig(recordName, updateObj);
                setIsModalOpen(false);
                setEditConfirmSpin(false);
                showSuccessNotification(response);
              } else {
                setEditConfirmSpin(false);
                setConfig(recordName, null);
              }
            }
          }
          resolve();
        })
        .catch(function (error) {
          setEditConfirmSpin(false);
          showErrorNotification(error);
          reject();
        });
    }).catch(() => {
      setEditConfirmSpin(false);
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  return (
    <>
      <span
        className="pointer color-515151 font-16 regular-font"
        onClick={() => showModal()}
      >
        {editButtonName}
      </span>
      <Modal
        className="form-modal"
        title={modalTitle}
        open={isModalOpen}
        width={width}
        okText={okText}
        cancelText={cancelText}
        confirmLoading={editConfirmSpin}
        closeIcon={<IoMdCloseCircle size="20" />}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              handleOk(values);
            })
            .catch((info) => {});
        }}
        onCancel={handleCancel}
      >
        <hr />
        <div className="px-3">
          <Form
            layout="vertical"
            initialValues={initialValues}
            name="Form"
            form={form}
          >
            {children}
          </Form>
        </div>
        <hr />
      </Modal>
    </>
  );
};
export default EditFormModal;
