import React from "react";
import Title from "../../../components/Title";
import Filter from "../../../components/Filter";
import AddCategory from "./AddCategory";

const TableHeader = () => {
  return (
    <div className="d-flex align-items-center justify-content-between flex-wrap">
      <Title text="Category" />
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
        <Filter
          SearchBox={true}
          SearchBoxWidth="200px"
          searchPlaceholder="ID / Name"
          apiName="getCategory"
          recordName="categoryList"
        />
        <AddCategory />
      </div>
    </div>
  );
};

export default TableHeader;
