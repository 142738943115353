import React, { useEffect } from "react";
import { Table } from "antd";
import { useApi } from "../../context/useApi";

const TableList = ({ apiName, recordName, columns }) => {
  const { config, DataGetAction } = useApi();
  const callApi = () => {
    DataGetAction(apiName, { query: "all" }, recordName);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callApi, []);

  return (
    <Table
      className="table-responsive"
      columns={columns}
      loading={config && config[`${recordName}_spin`]}
      dataSource={
        config && config[`${recordName}_spin`]
          ? []
          : (config && config[recordName] && config[recordName].tableData) || []
      }
    />
  );
};
export default TableList;
