import React from "react";
import { Input, InputNumber } from "antd";
const PackageForm = ({ Form, form }) => {
  return (
    <>
      <Form.Item
        name="packageName"
        label="Package Name"
        rules={[{ required: true, message: "Package name is required" }]}
      >
        <Input
          className="text-capitalize"
          readOnly
          placeholder="Enter your package name"
        />
      </Form.Item>
      <Form.Item
        name="packagePrice"
        label="Price"
        rules={[{ required: true, message: "Price is required" }]}
      >
        <InputNumber
          readOnly={
            form.getFieldValue("packageDuration") === "free" ? true : false
          }
          className="w-100"
          min={0.1}
          placeholder="Enter your price"
        />
      </Form.Item>
      <Form.Item
        name="packageDuration"
        label="Package Duration"
        rules={[{ required: true, message: "Package duration is required" }]}
      >
        <Input
          className="text-capitalize"
          readOnly
          placeholder="Enter your package name"
        />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: "Description is required" }]}
      >
        <Input.TextArea rows={3} placeholder="Enter your description" />
      </Form.Item>
    </>
  );
};

export default PackageForm;
