import React from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

const FillAreaChart = ({ data }) => {
  return (
    <div
      className="mt-3"
      style={{
        background: "#fff",
        boxShadow: "0px 0px 16px #5C5C5C1F",
        borderRadius: "8px",
      }}
    >
      <div className="p-3">
        <h4 className="regular-font font-22">Monthly Analysis</h4>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <Tooltip
            labelFormatter={function (value) {
              const dataForValue = data.filter((Item) => Item.name === value);

              return (
                <div>
                  <p style={{ color: "#10316B" }}>
                    Total Books : {dataForValue[0].totalBooks}
                  </p>
                  <p style={{ color: "#10316B" }}>
                    Total Sales {dataForValue[0].totalSales}
                  </p>
                </div>
              );
            }}
          />
          <XAxis dataKey="name" />
          <defs>
            <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#2569C3" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#EDF2F9" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <Area
            dataKey={"Total Stores"}
            strokeWidth={2}
            stackId="2"
            stroke="#10316B"
            fill="url(#color15)"
            fillOpacity={1}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FillAreaChart;
