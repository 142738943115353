import React, { useState } from "react";
import EditFormModal from "../../../components/Modal/EditFormModal";
import PhoneInput from "react-phone-input-2";
import { Form, Input } from "antd";

const EditUser = ({ record }) => {
  const [form] = Form.useForm();
  const [phoneNumber, setPhoneNumber] = useState();
  return (
    <EditFormModal
      apiName="updateUser"
      recordName="userList"
      initialValues={record}
      Form={Form}
      form={form}
    >
      <Form.Item
        name="fullName"
        label="Full Name"
        rules={[{ required: true, message: "Full name is required" }]}
      >
        <Input className='text-capitalize' />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        label="Phone Number"
        rules={[{ required: true, message: "Full name is required" }]}
      >
        <PhoneInput
          inputStyle={{
            width: "100%",
          }}
          country={"us"}
          value={phoneNumber}
          onChange={(phone) => setPhoneNumber({ phone })}
        />
      </Form.Item>
    </EditFormModal>
  );
};

export default EditUser;
