import React, { useContext, useReducer } from "react";
import AppContext from "./AppContext";
import { initialStates } from "./config";
import { configReducer } from "./configReducer";
import axios from "axios";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../utils/commonFunctions";
const ROUTE_URL = process.env.REACT_APP_ROOT_URL;
export const useApi = () => useContext(AppContext);

const Context = (props) => {
  const configState = {
    isCompletion: initialStates.isCompletion,
    user: initialStates.user,
    serverError: initialStates.serverError,
  };
  const [config, configDispatch] = useReducer(configReducer, configState);
  const setConfig = (key, value) => {
    configDispatch({
      type: "SET_CONFIG",
      payload: {
        key,
        value,
      },
    });
  };
  const DataRequestAction = (method, apiName, values, recordName) => {
    setConfig(`${recordName}_spin`, true);
    let Authorization = null;
    let user = JSON.parse(localStorage.getItem("userInfo"));
    if (user && user.token) {
      Authorization = user.token;
    }
    let headers = {
      "Content-Type": "application/json",
      Authorization: Authorization,
      APIType: "web",
    };
    axios({
      url: `${ROUTE_URL}/${apiName}`,
      method: method,
      headers: headers,
      data: values,
    })
      .then(function (response) {
        if (response && response.data && response.data.user) {
          if (recordName) {
            setConfig(recordName, response.data.user);
          }
        } else if (response && response.data && response.data.Record) {
          if (recordName) {
            setConfig(recordName, response.data.Record);
          }
        }
        setConfig(`${recordName}_success`, true);
        showSuccessNotification(response);
        setConfig(`${recordName}_spin`, false);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          setConfig("sessionExp", true);
          setConfig(`${recordName}_spin`, false);
          setConfig(`${recordName}_error`, false);
        } else {
          setConfig(`${recordName}_spin`, false);
          setConfig(`${recordName}_error`, true);
          showErrorNotification(error);
        }
      });
  };
  const DataGetAction = (apiName, values, recordName) => {
    setConfig(`${recordName}_spin`, true);
    let param = "";
    if (values) {
      param = `${JSON.stringify(values)}`;
    }

    let Authorization = null;
    let user = JSON.parse(localStorage.getItem("userInfo"));
    if (user && user.token) {
      Authorization = user.token;
    }
    let headers = {
      "Content-Type": "application/json",
      Authorization: Authorization,
      APIType: "web",
    };
    axios({
      url: `${ROUTE_URL}/${apiName}/${param}`,
      method: "get",
      headers: headers,
    })
      .then(function (response) {
        if (response && response.data && response.data.Record) {
          if (recordName) {
            setConfig(recordName, response.data.Record);
          }
          setConfig(`${recordName}_success`, true);
          setConfig(`${recordName}_spin`, false);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          setConfig("sessionExp", true);
          setConfig(`${recordName}_spin`, false);
          setConfig(`${recordName}_error`, false);
        } else {
          setConfig(`${recordName}_spin`, false);
          setConfig(`${recordName}_error`, true);
          showErrorNotification(error);
        }
      });
  };
  return (
    <AppContext.Provider
      value={{
        config,
        setConfig,
        configDispatch,
        DataRequestAction,
        DataGetAction,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default Context;
