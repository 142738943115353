import { Input } from "antd";
import React from "react";

const CategoryForm = ({ Form }) => {
  return (
    <Form.Item
      name="categoryName"
      label="Name"
      rules={[{ required: true, message: "Name is required" }]}
    >
      <Input />
    </Form.Item>
  );
};

export default CategoryForm;
