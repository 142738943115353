import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Popover } from "antd";
const Action = ({ children }) => {
  const [open, setOpen] = useState(false);
  const content = <div onClick={() => setOpen(false)}>{children}</div>;
  return (
    <Popover
      content={content}
      style={{ border: "1px solid #000" }}
      trigger="click"
      placement="left"
      open={open}
      onOpenChange={(e) => setOpen(e)}
    >
      <BsThreeDotsVertical size={22} style={{ cursor: "pointer" }} />
    </Popover>
  );
};

export default Action;
