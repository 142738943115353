import React from "react";
import { Button, Form, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useApi } from "../../context/useApi";
const Filter = ({
  label,
  value,
  Options,
  SearchBox,
  SelectBox,
  SelectBoxWidth,
  SearchBoxWidth,
  searchPlaceholder,
  selectPlaceholder,
  apiName,
  recordName,
}) => {
  const { DataGetAction } = useApi();
  const onFinish = (values) => {
    if (values && (values.name || values.status)) {
      DataGetAction(apiName, values, recordName);
    } else {
      DataGetAction(apiName, { query: "all" }, recordName);
    }
  };
  return (
    <Form
      onFinish={onFinish}
      className="d-flex flex-wrap align-items-center gap-2"
    >
      {SearchBox && (
        <Form.Item name="name" className="mb-0">
          <Input
            style={{ width: SearchBoxWidth }}
            placeholder={searchPlaceholder}
          />
        </Form.Item>
      )}
      {SelectBox && (
        <Form.Item name="status" className="mb-0">
          <Select
            style={{ width: SelectBoxWidth }}
            placeholder={selectPlaceholder}
          >
            {Options &&
              Options.map((item) => (
                <Select.Option value={item[value]}>{item[label]}</Select.Option>
              ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item className="mb-0">
        <Button type="primary" htmlType="submit" className="px-2">
          <div className="d-flex gap-2">
            <SearchOutlined className="bold-font" />
            <span>Search</span>
          </div>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Filter;
