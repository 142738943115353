import { Empty } from "antd";
import React from "react";
import { BsFillCircleFill } from "react-icons/bs";
import moment from 'moment/moment'
const Notification = ({ data }) => {
  const renderNotifications = () => {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <div className="ps-3 pe-2 d-flex  gap-2 mb-2">
            <span>
              <BsFillCircleFill size={"11px"} color="#E51022" />
            </span>
            <div className="d-flex flex-column w-100">
              <span className="mb-0 font-14 regular-font">
                {item.description}
              </span>
              <span className="mb-0 font-12 bold-font color-8C9197">
                {moment(item.createdAt).format('DD-MM-YYYY hh:mm')}
              </span>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Empty />
        </div>
      );
    }
  };
  return (
    <div
      className="h-100 w-100 bg-white"
      style={{ boxShadow: "0px 0px 16px #5C5C5C33", borderRadius: "8px" }}
    >
      <h4 className="pt-3 mb-0 ps-3 regular-font font-26">Notifications</h4>
      <hr style={{ color: "#9c9ea5" }} />
      <div style={{ overflowX: 'hidden', overflowY: 'auto', height: '444px' }}>
        {renderNotifications()}
      </div>
    </div>
  );
};

export default Notification;
