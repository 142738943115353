import React from "react";
import { Form, Input, Button } from "antd";
import { useNavigate, useParams } from "react-router";
import { useApi } from "../../../../context/useApi";
const SetNewPasswordForm = () => {
  const params = useParams();

  const navigate = useNavigate();
  const { config, setConfig, DataRequestAction } = useApi();
  const onFinish = (values) => {
    delete values.confirmPassword;
    values.forgetPasswordAuthToken = params && params.query;
    DataRequestAction("post", "setNewPassword", values, "SetNewPassword");
  };
  if (config && config.SetNewPassword_success) {
    navigate("/login");
    setConfig("SetNewPassword_success", false);
  }
  return (
    <Form layout="vertical" className="auth-form" onFinish={onFinish}>
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: "Password is required" }]}
      >
        <Input.Password placeholder="Type new password" />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label="Confirm Password"
        rules={[
          {
            required: true,
            message: "Please Enter Confirm Password",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Password is not match"));
            },
          }),
        ]}
      >
        <Input.Password placeholder="Re-Type password" />
      </Form.Item>
      <Button
        disabled={config.SetNewPassword_spin}
        loading={config.SetNewPassword_spin}
        type="primary"
        htmlType="submit"
        className="w-100 extra-bold-font h-40px mt-2"
      >
        Update Password
      </Button>
    </Form>
  );
};

export default SetNewPasswordForm;
